

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Bold.ttf');
}

@font-face {
  font-family: 'NeueHass';
  src: url('../public/fonts/NeueHaasDisplayMediu.ttf');
}

@font-face {
  font-family: 'NeueHassThin';
  src: url('../public/fonts/NeueHaasDisplayLight.ttf');
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fullscreen_container {
  height: 100vh;
  width: 100%;
}

p {
  margin: 0;
  margin-bottom: 10px;
}

.font_tech {
  font-family: 'ShareTech';
}

.font_courier {
  font-family: 'Courier New', Courier, monospace;
}

.font_neue {
  font-family: 'NeueHass';
  /* font-weight: bold; */
}

.font_inter {
  font-family: 'Inter';
  /* font-weight: bold; */
}

.bg {
  /* background: #000; */
  width: 100%;
}

.page_container {
  background: #000;
  width: 100%;
  display: flex;
  justify-content: center;
}

.page_content {
  width: 100%;
  /* margin-top: 5vw;
  margin-bottom: 5vw; */
  margin: 5vw;
}

.container {
  background-color: rgb(11, 11, 13);
  width: 1440px;
  position: relative;
}

.orange {
  color: #F7931A;
}

.page_title {
  font-size: large;
  font-weight: bold;
  color: #F7931A;
  font-family: 'Inter';
}

.page_info {
  color: rgba(255, 255, 255, 0.50);
  font-family: 'NeueHassThin';
  font-size: x-small;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  letter-spacing: 0.8px;
}

.pc {
  display: none;
}

.mobile {
  display: flex;
}

.page_img_container {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}

.page_img {
  position: relative;
  width: 70%;
  border: #F7931A 2px solid;
}


@media screen and (min-width:800px) {
  .pc {
    display: flex;
  }

  .mobile {
    display: none;
  }

  .page_content {
    margin-top: 1vw;

  }

  .page_title {
    font-size: xx-large;
  }

  .page_info {
    font-size: 18px;
    line-height: 1.3;
    /* font-weight: 100; */
  }

  .page_img_container {
    height: 40vw;
    width: 100%;
    position: relative;
    top: -5vw;
    justify-content: flex-start;
  }

  .page_img {
    width: 90%;
  }
}
.revolution_container {
    /* padding-top: 20px; */
    width: 100%;
    height: 40vw;
    flex-shrink: 0;
    position: relative;
    display: flex;
    justify-content: center;

    /* background-repeat: no-repeat; */
}

.revolution_black_bg {
    width: 100vw;
    height: 100%;
    position: absolute;
    background-color: #000;
}

.revolution_content {
    margin-top: 30px;
    width: 90vw;
    height: 30vw;
    background: url('/public/img/bg-2.png') grey;
    background-blend-mode: multiply;
    background-size: cover;
    background-position-y: 45vw;
    position: absolute;
    border: #F7931A 1px solid;
}

.revolution_paint {
    width: 100%;
    height: 100%;
    background: #F7931A;
    mix-blend-mode: color;
    position: absolute;


}

.revolution_info_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: whitesmoke;
    font-size: x-small;
}

.revolution_info_logo {
    width: 8vw;
}

@media screen and (min-width:800px) {
    .revolution_container {
        width: 100%;
        height: 40vw;
    }

    .revolution_content {
        background-position-y: 45vw;

    }

    .revolution_info_container {
        font-size: xx-large;
        font-weight: bold;
        gap:4vw;
    }
}

.revolution_info {
    height: 100px;
    width: 100%;
    color: whitesmoke;
    font-size: large;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
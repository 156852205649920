.nav {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #F7931A;
    background: rgba(11, 11, 13, 1);
    backdrop-filter: blur(10px);
    position: relative;
    z-index: 2;
}

.nav_logo {
    width: 20px;
    margin-left: 10px;
}

.nav_title {
    font-size: small;
    color: white;
}

@media screen and (min-width:800px) {
    .nav {
        height: 80px;
    }

    .nav_logo {
        width: 40px;
        margin-left: 10px;
    }

    .nav_title {
        font-size: large;
    }
}

.select_lang {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.valuable_pc {
    display: none;
    background-color: lightgray;
    background-size: cover;

}

.valuableTitle {
    font-family: 'ShareTech';
    font-size: x-large;
    padding: 20px;

}

.foundHistory {
    font-size: medium;
    font-family: 'ShareTech';
    margin-bottom: 20px;
}


.valuable_left_grey_border {
    padding-left: 10px;
    padding-right: 20px;
    border-left: 10px grey solid;
}

.valuable_right_grey_border {
    padding-right: 10px;
    padding-left: 20px;
    border-right: 10px grey solid;
}

.valuable_no_border {
    padding-left: 20px;
    padding-right: 20px;
}

.small_coin {
    width: 10vw;
    height: 10vw;
    position: relative;
    object-fit: cover;
    bottom: 40vw;
    left: 20vw;
}

.big_coin {
    object-fit: cover;
    width: 50vw;
    position: relative;
    left: 5vw;
    top: 6vw;
}

@media screen and (min-width:800px) {
    .valuable_pc {
        display: block;
    }

    .valuable_mobile {
        display: none;
    }

    .valuableTitle {
        font-size: 56px;
    }

    .foundHistory {
        font-size: large;
    }

    .valuable_left_grey_border {
        padding-left: 10px;
        padding-right: 20px;
        border-left: 40px grey solid;
    }

    .valuable_right_grey_border {
        padding-right: 0px;
        padding-left: 20px;
        border-right: 40px grey solid;
    }

    .valuable_no_border {
        padding-left: 50px;
        padding-right: 20px;
    }

    .small_coin {
        width: 10vw;
        height: 10vw;
        position: relative;
        left: 7vw;
        top: 6vw;
        right: 0vw;
        bottom: 0vw;
    }

    .big_coin {
        object-fit: cover;
        width: 35vw;
        position: relative;
        top: -5vw;
        left: 5vw;
    }
}
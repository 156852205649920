

.futureTitle {
    font-family: 'ShareTech';
    font-size: x-large;
    padding: 20px;

}

.foundHistory {
    font-size: medium;
    font-family: 'ShareTech';
    margin-bottom: 20px;
}



.future_left_grey_border {
    padding-left: 10px;
    padding-right: 20px;
    border-left: 10px grey solid;
}

.future_right_grey_border {
    padding-right: 10px;
    padding-left: 20px;
    border-right: 10px grey solid;
}

.future_no_border {
    padding-left: 20px;
    padding-right: 20px;
}


@media screen and (min-width:800px) {
    .future_pc {
        display: block;
    }

    .future_mobile {
        display: none;
    }

    .futureTitle {
        font-size: 56px;
    }

    .foundHistory {
        font-size: large;
    }

    .future_left_grey_border {
        padding-left: 10px;
        padding-right: 20px;
        border-left: 40px grey solid;
    }

    .future_left_gold_border {
        padding-left: 10px;
        padding-right: 20px;
        border-left: 40px darkgoldenrod solid;
    }

    .future_right_grey_border {
        padding-right: 0px;
        padding-left: 20px;
        border-right: 40px grey solid;
    }

    .future_no_border {
        padding-left: 50px;
        padding-right: 20px;
    }

}
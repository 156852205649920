.progressive_bg {
    background-color: #F7931A;
}


.step_container {
    width: 80vw;
    border: black 2px solid;
    display: flex;
    gap: 3vw;
    margin-bottom: 1vw;
    padding: 5px;
}

.step_img {
    width: 5vw;
    object-fit: contain;
}

.step_title {
    font-size: x-small;
    font-weight: bold;
}

.step_text {
    font-size: x-small;
}

@media screen and (min-width:800px) {
    .step_container {
        width: 38vw;
        margin-left: 3vw;
        margin-right: 3vw;
        gap: 1vw;
    }

    .step_img {
        width: 30px;
        object-fit: contain;
        margin-left: 2vw;
    }

    .step_text_container {
        margin: 1vw;
    }

    .step_title {
        font-size: medium;
        font-weight: bold;
    }

    .step_text {
        font-size: small;
    }
}
.hero_bg {
    background: url('../../../public/img/hero_bg.png'), black no-repeat;
    /* background: url(<path-to-image>), lightgray -0.304px 0px / 100.042% 118.692% no-repeat; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

}

.hero_container {
    width: 100%;
    height: 200px;
    background: #000;
    filter: blur(10px);
}

.bg_container {
    /* height: calc(100vh + 180px); */
    width: 100%;
    height: 200px;
    flex-shrink: 0;
    position: relative;
    /* top: -40px; */
}

.hero_union_pc {
    display: none;
}

.hero_union_mobile {
    display: block;
}

.hero_logo {
    width: 150px;
    height: 150px;
    position: relative;
}

.grey_cover {
    width: 100%;
    height: 100vh;
    background: rgba(43, 43, 43, 0.5);
    position: absolute;
}


.title {
    font-size: xx-large;
    color: white;
    position: relative;
}

.hero_info_row {
    position: absolute;
    top: 15px;
    word-break: break-all;
}

.hero_info {
    font-size: medium;
    color: white;
    font-size: medium;
}

.hero_info_orange {
    color: #F7931A;
}

.linear_block {
    width: 100%;
    height: 88px;
    background: linear-gradient(0deg, #000 0%, rgba(11, 11, 13, 0.00) 84.98%);
    position: absolute;
    bottom: 0;
}

@media screen and (min-width:800px) {
    .hero_info_row {
        position: absolute;
        top: 90px;
        word-break: break-all;
    }

    .hero_info {
        word-break: break-all;
        font-size: 58px;
        font-weight: bold;
    }

    .hero_container {
        height: 870px;

    }

    .linear_block {
        height: 400px;
    }

    .bg_container {
        /* height: calc(100vh + 180px); */
        width: 100%;
        height: 870px;
        flex-shrink: 0;
        position: relative;
        /* top: -40px; */
    }


    .title {
        font-size: 58px;
    }

    .hero_union_pc {
        display: block;
    }

    .hero_union_mobile {
        display: none;
    }
}
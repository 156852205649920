.footer_link {
    cursor: pointer;
    text-decoration: none;
    font-family: 'ShareTech';
    color: whitesmoke;
}

.footer_icon {
    width: 20px;
    height: 20px;
}

.footer_copyright {
    font-size: xx-small;
}

@media screen and (min-width:800px) {
    .footer_icon {
        width: 40px;
        height: 40px;
    }

    .footer_copyright {
        font-size: small;
    }
}